import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'
import Teaser from '../../components/Teaser'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Why You Need a Podcast Teaser / Podcast Trailer"
          description="Does your podcast have a teaser trailer? Here is why yuo need one to grow your podcast organically though social media!"
        />

        <article>
          <h1>Why You Need a Podcast Teaser Trailer</h1>

          <p>
            There are currently <a href="https://www.podcastinsights.com/podcast-statistics/">over 850,000</a> podcasts
            in production, and new ones are getting started every day. Are you thinking about creating your own podcast?
            Do you already have one up and running?
          </p>
          <p>
            In either case, you need to make sure you record a teaser or trailer for it. Read on to learn all the
            benefits a trailer has to offer, even if you’ve already started releasing podcast episodes.
          </p>

          <p>
            <img src="/images/podcast-trailer.jpg" alt="Podcast Trailer" />
          </p>

          <h2>What Is a Podcast Trailer?</h2>
          <p>
            Podcast trailers or teasers are short audio snippets (usually only a few minutes in length) that help
            listeners get to know you (and your co-hosts) and figure out what your podcast is all about. 
          </p>
          <p>
            There are a few different types of trailers you can create, including an “intro” trailer that covers the
            basics of your show, a “coming soon” trailer to get people excited about the podcast’s upcoming release, and
            a “new season” trailer that lets everyone know new episodes are on the way. 
          </p>

          <h3>Podcast teaser example</h3>
          <div className={styles.teasers}>
            <Teaser preview="99pi.jpg" previewGif="99pi.gif" videoLocation="99pi.mp4" />
            <Teaser
              preview="thisamericanlife.png"
              previewGif="thisamaricanlife.gif"
              videoLocation="thisamaricanlife.mp4"
            />
            <Teaser preview="lore.jpg" previewGif="lore.gif" videoLocation="lore.mp4" />
            <Teaser
              preview="everythingisalive.jpg"
              previewGif="everythingisalive.gif"
              videoLocation="everythingisalive.mp4"
            />

            <br />
          </div>
          <h2>Benefits of a Podcast Trailer</h2>
          <p>
            No matter what kind of trailer you record, there are lots of benefits that come with having one. The
            following are some of the most noteworthy ones:
          </p>
          <h3>Evergreen Introduction</h3>
          <p>
            The great thing about recording an “intro” trailer for your podcast is that it’s an evergreen piece of
            content (this means it’ll be up forever and will always be relevant). With a great intro trailer, you can
            let new listeners know what your podcast is all about and get them excited to listen to it. 
          </p>
          <p>
            Even when you’ve got hundreds of episodes or multiple seasons under your belt, you can continue using that
            teaser to bring in new listeners. It’s the gift that keeps on giving.
          </p>
          <h3>Set the Tone</h3>
          <p>
            You can use your trailers to introduce people to the tone of your podcast, too, not just the type of content
            you’re putting out there. 
          </p>
          <p>
            Many people include short audio clips from previous or upcoming episodes to show listeners how they
            communicate with their co-hosts or guests. This helps potential new listeners decide if they like the
            rapport you have with those you talk to on your show and can convince them to subscribe and hear what you
            have to say.
          </p>
          <h3>Provide Context</h3>
          <p>
            Podcast trailers can help you give listeners context for your show, too. You can provide them with some
            basic facts or information on events that led up to your creating this show (this can be especially helpful
            if your podcast is based around a particular event or time in history). This helps them ensure they’re up to
            speed and have common questions answered before they start listening.
          </p>
          <h3>Build an Audience Early</h3>
          <p>
            If you post a trailer for your podcast before you even have any episodes up, this gives you a chance to
            start building an audience early. When you do this, you can increase the likelihood that people will
            actually listen to your first few episodes. 
          </p>
          <p>
            Sharing a trailer gives them something to look forward to. It also encourages them to subscribe so that the
            show automatically appears in their feed once new episodes start going live.
          </p>
          <h3>Create Loyal Listeners</h3>
          <p>
            With the right trailer, you can improve your chances of turning someone who happens to stumble upon your
            podcast into a loyal listener. This is your elevator pitch, your chance to sell them on the content and
            style of your podcast and invite them to come back or stick around to listen to more. 
          </p>
          <p>
            Because podcast trailers are so short, they lower the barrier to entry. Someone might not be willing to
            listen to a full 45-minute episode of a podcast they’ve never heard of before. They might be able to spare
            two or three minutes to hear your trailer, though. You can use this to hook them and get them subscribed to
            your show for life!
          </p>

          <section>
            <GetStartedOrChat cta="Use Echowave to create a free podcast trailer!" />
            <br />
          </section>

          <h2>Record Your Trailer Today</h2>
          <p>
            As you can see, there are tons of advantages that come with recording a podcast trailer, even if you already
            have episodes out for people to listen to. A trailer helps new listeners figure out what your podcast is all
            about and leaves people wanting more when you’re getting ready to release your new show. 
          </p>
          <p>
            <br />
            Keep these tips in mind, and don’t forget to check out our other resources to learn <em>how </em>to create a
            great podcast trailer (and other great podcasting tips and tricks).
          </p>

            <br/>
            <h2>Frequently Asked Questions</h2>
            <FAQ items={[
                {
                    'question': 'How to make a podcast trailer?',
                    'answer': 'You can create a trailer using FFMPEG from an audio clip, If you already have a sound file you can use EchoWave'
                },
                {
                    'question': 'What is the ideal length for a podcast trailer?',
                    'answer': 'Try to keep the trailer under 90 seconds; Keeping the teaser punchy and concise.'
                },
            ]} />

        </article>
      </Layout>
    )
  }
}

export default Page
